.factors {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10.83px;
}

.factor {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 10.81px;
  line-height: 15px;
  width: 90%;
}

.arrow {
  height: 14.45px;
}
