* {
  margin: 0;
  padding: 0;
}

.pillar {
  width: 242px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 112px;
  height: 103px;
  margin-bottom: 5px;
}

.container {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
  position: relative;
}

.imgBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.inner {
  width: 234px;
  position: absolute;
}

.pillarTitle {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  z-index: 100;
  margin-top: 4px;
}

.pillarTitleHide {
  margin-top: 4px;
  color: transparent;
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 14px;
  line-height: 27px;
  text-align: center;
}

.description {
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 4px;
  padding: 0 4px;
  text-align: center;
}

.descriptionHide {
  color: transparent;
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 13px;
  padding: 0 4px;
  text-align: center;
}
