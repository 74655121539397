.innerContainer {
  list-style: none;
  margin: 0 auto;
  padding: 20px 0px;
  display: flex;
}

.testItem {
  flex: 1;
}

.desctiption {
  font-size: 12px;
  line-height: 17px;
  padding: 5px;
}
