.container{
    width:590px;
    margin:30px auto
}

.header{
    display: flex;
    justify-content: space-between;
}

.title{
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 14px;
    line-height: 14px;
 padding:10px 0;
    flex:1;
    text-align: center;
    border-bottom: 2px solid var(--primary50) ;
}

.column{
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid var(--primary50);
}

.lineTitle{
    height:70px;
    font-family: "AktivGroteskEx_A_Md", serif;
    font-size: 14px;
    line-height: 14px;
    padding-top:26px;
    border-bottom: 2px solid var(--primary50) ;
}

.dot{
    width:25px;
    height:25px;
    border-radius: 50%;
    background-color: var(--primary600);
    align-self: center;
    margin:-4px auto;
}

.excellent {
    background-color: var(--secondary12);
}
.good {
    background-color: var(--secondary07);
}

.neutral {
    background-color: var(--secondary20);
}

.limitations {
    background-color: var(--secondary50);
}
.severeLimitation {
    background-color: var(--secondary40);
}

.legend{
    margin:20px auto;
    display: flex;
    gap:15px;
}
