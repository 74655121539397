.loaderContainer {
  padding-top: 100px;
  width: 794px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderTitle {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 22px;
  color: var(--primary900);
}
