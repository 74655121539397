.section {
  position: relative;
}

.subtitle {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin-left: 41px;
  margin-top: 11px;
}

.biaxial {
  font-size: 9px;
  line-height: 17px;
  margin-top: 25px;
  margin-left:-10px;
  position:relative;
}

.legend {
  margin-left: 70px;
  margin-top:0px;
  display: flex;
  font-family: "AktivGroteskEx_A_Md", sans-serif;
  font-size: 10px;
  align-items: center;
}

.legend2 {
  display: flex;
  gap: 15px;
  align-items: center;
}

.inner{
  display: flex;
  position:relative;
}

.table{
  width:310px;
  margin-left:-50px;
  margin-top:20px
}

.header{
  display: flex;
  align-items: center;
  gap:20px;
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 9px;
  line-height: 11px;
  margin-bottom: 10px;
}

.row{
  height:33px;
  display: flex;
  gap:20px;
  align-items: center;
  font-family: "AktivGroteskEx_A_Md", sans-serif;
  font-size: 8px;
  line-height: 10px;
  border-bottom: 0.6px solid var(--primary800);
}

.noLine{
  border: none;
}

.column1{
  flex:2;
  padding-right:10px
}

.column2{
  flex:1.5;
  text-align: center;
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 9px;
}

.column3{
  flex:2;
}

.ruler{
  width:55px;
  height:297px;
  opacity: 0.6;
  background: linear-gradient(180deg, rgba(32, 221, 164, 0.944454) 6.54%, rgba(32, 176, 221, 0.944454) 25.75%, rgba(48, 68, 215, 0.877031) 39.16%, rgba(115, 76, 188, 0.6) 49.67%, rgba(227, 141, 22, 0.6) 59.65%, rgba(227, 141, 22, 0.4) 71.98%, rgba(240, 37, 104, 0.3) 82.85%, rgba(240, 37, 104, 0.6) 97.93%);
  position:absolute;
  top:52px;
  left:925px;
  z-index: -1;
}

.description{
  width:355px;
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 10px;
  line-height: 14px;
  padding: 20px;
}

.separators{
  width:683px;
  height:330px;
  display:flex;
  position:absolute;
  bottom:25px;
  left:78px;
}

.separatorContainer{
  flex:1;
  align-items: center;

}

/*.separatorContainer:nth-child(7){*/
/*  border-left:8px solid var(--primary100)*/
/*}*/
