.header {
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 13.3784px;
  padding-left: 10px;
  padding-bottom: 4px;
  display: flex;
  gap: 5px;
}

.bold {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
}

.inner {
  position: relative;
  width: 714px;
  height: 30.58px;
  margin-top: 1px;
  background-color: var(--primary50);
  border-radius: 58.2916px;
}

.container {
  width: 694px;
  height: 15.29px;
  border-radius: 25.8012px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin: 0 10px;
}

.fill {
  background-color: var(--primary800);
  width: 100%;
  height: 100%;
  border-radius: 25.8012px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.excellent {
  background-color: var(--secondary12);
}
.good {
  background-color: var(--secondary07);
}

.neutral {
  background-color: var(--secondary20);
}

.limitations {
  background-color: var(--secondary50);
}
.severeLimitation {
  background-color: var(--secondary40);
}

.pointer {
  height: 15.29px;
  border-right: 1px solid var(--primary50);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.poinet__q1 {
  width: 20%;
}

.poinet__q2 {
  width: 40%;
}

.poinet__q3 {
  width: 60%;
}
.poinet__q4 {
  width: 80%;
}

.labels {
  width: 100%;
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 7px;
  color: var(--primary600);
  line-height: 23px;
  margin-top: 4px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-20%);
}

.labels li {
  width: 100%;
  text-align: center;
}

.iconContainer {
  width: 714px;
}

.iconInner {
  width: 100px;
  margin: 0 auto;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconLabel {
  font-family: "AktivGroteskEx_A_Md", sans-serif;
  font-size: 9.4642px;
  line-height: 16px;
  text-align: center;
}

.iconImg {
  width: 40px;
  height: 40px;
}
