.section {
  width: 95%;
  margin: 0 auto;
}

.sectionTitle {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 19.6615px;
  line-height: 12px;
}

.chartContainer {
  margin: 0 auto;
  padding-left: 80px;
  padding-top: 60px;
}
