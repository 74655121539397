.inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 30px;
  padding-top: 10px;
  list-style: none;
}

.header {
  display: flex;
  gap: 5px;
  align-items: center;
}

.icon {
  width: 11px;
  height: 20px;

  background-color: var(--primary900);
}

.subtitle {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 16px;
  line-height: 25px;
}

.description {
  width: 100%;
  height: 237px;
  font-size: 12px;
  line-height: 17px;
  padding-top: 18px;
}
