.chartTitle {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 15.303px;
  line-height: 24px;
  position: absolute;
  top: -45px;
  left: 145px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 12.6px;
  line-height: 16.13px;
  text-align: center;
}

.value {
  font-family: "AktivGroteskEx_A_Rg";
  font-size: 12.6px;
  line-height: 16.13px;
}

.cardioTag {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  top: 0px;
  left: 223px;
  transform: translate(-50%, -50%);
}

.resistanceTag {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  top: 310px;
  right: -13px;
  transform: translate(-50%, -50%);
}

.intervalTag {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  top: 310px;
  left: 62px;
  transform: translate(-50%, -50%);
}

.legendTag1 {
  font-size: 12.6025px;
  line-height: 16px;
  position: absolute;
  top: 287px;
  left: 108px;
}
.legendTag2 {
  font-size: 12.6025px;
  line-height: 16px;
  position: absolute;
  top: 287px;
  right: 14px;
}
.legend {
  width: 420px;
  font-family: "AktivGroteskEx_A_Lt";
  font-size: 9.71698px;
  line-height: 12px;
  position: absolute;
  top: 340px;
  left: 22px;
}
