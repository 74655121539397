.container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    position: relative;
}

.inner {
    width: 146px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.innerTop {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 60px;
}

.arrowStyle {
    margin-bottom: 50px;
}

.text {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    width: 70%
}

.iconCardTitle {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin: 0 auto 4px;
}

.bracketContainer {
    display: flex;
    gap: 4px;
    align-items: center;

}

.bracketInner {
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bracketText {
    font-family: "AktivGroteskEx_A_Rd", sans-serif;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
    margin: 2px auto 4px;
}

.arrowStyleRound01 {
    position: absolute;
    bottom: -10px;
    right: 100px;
}

.arrowStyleRound02 {
    position: absolute;
    bottom: 50px;
    right: 120px;
}

.arrowStyleRound03 {
    position: absolute;
    bottom: 50px;
    left: 100px;
}

.arrowStyleRound04 {
    width:65px;
    height: 65px;
    position: absolute;
    top: 100px;
    left: 90px;
}

.arrowStyleRound05 {
    width:65px;
    height: 65px;
    position: absolute;
    top: 100px;
    right: 90px;
}

.subTitle {
    display: flex;
    gap:4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 16px auto 8px;
}

.subTitleText{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 18px;
    text-align: center;
}

.lungIconInner{
    margin-top: 25px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    gap:35px
}

.lungIconText{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 7px;
    text-align: center;
}
