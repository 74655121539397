.container {
  width: 374px;
  padding: 9px 0px;
  border-bottom: 2px solid var(--primary300);
  margin: 0 auto;
  margin-top: 70px;

  display: flex;
  justify-content: space-between;
}

.innerLeft {
  width: 153px;

  font-size: 14.2024px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.base {
  width: 70px;
  margin: 0 auto;
  margin-bottom: 37px;
}

.baseImg {
  width: 100%;
}
