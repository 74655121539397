.container {
  width: 100%;
  display: flex;
  gap: 45px;
}

.ageClock {
  margin-left: 50px;
}

.inner {
  flex: 0 0 170px;
  padding: 40px 5px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 11.7154px;
  line-height: 15px;
  margin-bottom: 20px;
}

.memo1 {
  margin-top: 18.82px;
}

.memo2 {
  margin-top: 69.73px;
}
