.description {
  font-family: "AktivGroteskEx_A_Rg", serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--primary1000);
  padding: 0 27px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 12.81px;
  margin-top: 117px;
}
