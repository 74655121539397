.labels {
    display: flex;
    flex-direction: column;
}
.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.title {
    width: 50px;
    height: 50px;
    background-color: var(--primary50);
    border-radius: 50%;
    font-family: "AktivGroteskEx_A_XBd", serif;
    font-size: 10px;
    line-height: 17px;
    text-align: center;
    padding-top: 15px;
}

.tag {
    width: 30px;
    font-family: "AktivGroteskEx_A_XBd", serif;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
}

.proteinTag {
    display: flex;
    align-items: center;
    gap: 3px;
    position: absolute;
    top: 60px;
    left: 150px;
    transform: translate(-50%, -50%);
}

.carbsTag {
    display: flex;
    align-items: center;
    gap: 3px;
    position: absolute;
    bottom: 35px;
    right: -103px;
    transform: translate(-50%, -50%);
}

.fatsTag {
    display: flex;
    align-items: center;
    gap: 3px;
    position: absolute;
    bottom: 35px;
    left: 20px;
    transform: translate(-50%, -50%);
}
