.chart {
  padding-top: 18px;
  padding-left: 7px;
}

.section{
  padding:0 16px;
  display: flex;
  flex-direction: column;
  gap:16px;
  margin:24px auto;
}

.inner {
  width: 95%;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

.sub {
  flex: 1;
}

.card_container {
  margin-top: 0px;
}
.suggestion_tables_title {
  font-family: "AktivGroteskEx_A_Bd", serif;
  font-size: 18px;
  line-height: 30px;
  width: 93%;
  margin: 0 auto 8px;
}
.suggestion_tables {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.qrCodeContainer{
  position: absolute;
  bottom:24px;
  left:16
}

.titleStress{
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  font-size: 25px;
  line-height: 15px;
  padding-top: 8px;
  margin-left:10px;
  margin-bottom:15px;
}

.imgStressBar{
  /*margin-top:24px;*/
  /*margin-left:16px*/
}


