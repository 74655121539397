

.header{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom:-4px
}

.sectionTitle{
    font-family: "AktivGroteskEx_A_Md", serif;
    font-size: 16px;

}

.list{

    padding-left: 16px;
    list-style: none;
    margin-left:12px;
    display:flex;
    flex-direction: column;
}

.listItem{
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 10.5px;
    margin:6px 0 0;
    padding-left: 9.5px;
}

.title{
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 12px;
    line-height: 22px;
}

.text{
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 10px;
    line-height: 16px;
}
