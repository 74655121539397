.labels {
  display: flex;
  flex-direction: column;
}
.bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.title {
  width: 70px;
  height: 70px;
  background-color: var(--primary50);
  border-radius: 50%;
  font-family: "AktivGroteskEx_A_XBd";
  font-size: 13.3452px;
  line-height: 17px;
  text-align: center;
  padding-top: 25px;
}

.tag {
  width: 50px;
  font-family: "AktivGroteskEx_A_XBd";
  font-size: 12.6px;
  line-height: 16.13px;
  text-align: center;
}

.proteinTag {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  top: -30px;
  left: 220px;
  transform: translate(-50%, -50%);
}

.carbsTag {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  top: 285px;
  right: -63px;
  transform: translate(-50%, -50%);
}

.fatsTag {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  top: 285px;
  left: 36px;
  transform: translate(-50%, -50%);
}
