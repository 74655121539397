.container {
  width: 491px;
  height: 90%;
  margin: 0 auto;
  margin-top: 50px;
  margin-left: 44px;
  padding: 6px;

  position: relative;
  display: flex;
  flex-direction: column;
}

.mainLabel {
  width: 160px;
  margin: 0 auto;
  display: flex;
  font-size: 13px;
  flex-direction: column;
}

.value {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 22px;
  line-height: 29px;
  text-align: center;
}

.description {
  text-align: center;
}

.ageChartPage {
  position: relative;
}
