.pointsAgeLabels {
  width: 494px;
  height: 247px;

  position: absolute;
  top: 10px;
  left: -10px;
  z-index: 0;
}

.pointAgeLabel0 {
  width: 20px;
  position: absolute;
  top: 232px;
  left: 30px;
}
.pointAgeLabel10 {
  width: 20px;
  position: absolute;
  top: 170px;
  left: 42px;
}
.pointAgeLabel20 {
  width: 20px;
  position: absolute;
  top: 110px;
  left: 74px;
}
.pointAgeLabel30 {
  width: 20px;
  position: absolute;
  top: 62px;
  left: 120px;
}
.pointAgeLabel40 {
  width: 20px;
  position: absolute;
  top: 32px;
  left: 177px;
}
.pointAgeLabel50 {
  width: 20px;
  position: absolute;
  top: 20px;
  left: 241px;
}
.pointAgeLabel60 {
  width: 20px;
  position: absolute;
  top: 31px;
  right: 169px;
}
.pointAgeLabel70 {
  width: 20px;
  position: absolute;
  top: 60px;
  right: 111px;
}
.pointAgeLabel80 {
  width: 20px;
  position: absolute;
  top: 108px;
  right: 64px;
}
.pointAgeLabel90 {
  width: 20px;
  position: absolute;
  top: 168px;
  right: 34px;
}
.pointAgeLabel100 {
  width: 20px;
  position: absolute;
  top: 233px;
  right: 32px;
}
