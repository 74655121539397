.container {
  height: 278px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}

.position1 {
  top: -58px;
  left: 222px;

  transform: translate(-50%, -50%);
}

.position2 {
  top: -26px;
  left: 222px;
  transform: translate(-50%, -50%);
}

.arrow {
  height: 195px;
  width: 15px;
  z-index: 0;
}
.arrowLabel {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary900);
  font-family: "AktivGroteskEx_A_Bd";
  color: var(--primary50);
  font-size: 17px;
  margin: 0 auto;
  padding-top: 5px;

  text-align: center;
}
