.container{
    /*width:400px;*/
    height:531px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 80px auto;
    padding-top: 120px;
}

.imgBreathScoreContainer{
    margin-top: 80px;
}

.innerTop{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
    position:relative;
}

.innerBottom{
    width:400px;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
}

.centered{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}


.boxesCenter{
    /*position:absolute;*/
    /*top:50%;*/
    /*left:50%;*/
    /*transform: translateX(-50%);*/
}

.boxesLeftRight{
    display: flex;
    align-items: center;
    gap:32px;
    position:relative;
}


.box01{
    width:140px;
    height:140px;
    border-radius: 50%;
    background-color: var(--primary50);
    text-align: center;
    padding: 0 8px;
    color:var( --secondary22);
    font-family: "AktivGroteskEx_A_XBd", serif;
    font-size: 16px;
    margin:0 auto 16px;
    position: relative;
}

.box02{
    width:88px;
    height:88px;
    border-radius: 50%;
    background-color: var( --secondary22);
    text-align: center;
    color:var(--primary50);
    font-family: "AktivGroteskEx_A_Md", serif;
    font-size: 12px;
    line-height: 14px;
    margin:0 auto;
    position: relative;
}

.description{
    width:240px;
    background-color: var(--primary50);
    border: 2px solid var( --secondary22);
    padding:16px 8px;
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 9px;
    line-height: 10px;
    border-radius: 16px;
}

.D01{
    position:absolute;
    bottom:75px;
    left:42%;
    transform: translateX(-50%);
}

.D02{
    position:absolute;
    top:112px;
    left:-140px

}

.D03{
    position:absolute;
    top:112px;
    left:-10px;
}


.line01{
    width:70px;
    height:3px;
    background-color: var(--primary1000);
    position:absolute;
    top:320px;
    left:435px;
    transform: rotate(15deg);
}

.line02{
    width:70px;
    height:3px;
    background-color: var(--primary1000);
    position:absolute;
    top:320px;
    left:250px;
    transform: rotate(-15deg);
}

.line03{
    width:70px;
    height:3px;
    background-color: var(--primary1000);
    position:absolute;
    top:220px;
    left:340px;
    transform: rotate(90deg);
}
