.rowItems {
  font-size: 10px;
  padding: 2px;
  line-height: 13px;
  flex-direction: column;

  flex: 1;
}

.rowItem {
  display: flex;
  align-items: center;
  border-bottom: 3px solid var(--primary100);
  list-style: none;
  padding: 0 12px;
}

.rowItem:last-child {
  border-bottom: none;
}

.cellItem {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 20px;
  list-style: none;
}

.cell {
  flex: 1;
  text-align: center;
}
