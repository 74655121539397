.backgroundImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
}

.header {
  width: 100%;
  height: 163px;
  position: relative;
}

.image {
  width: 100%;
  height: 167px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.title {
  width: 100%;
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 25px;
  line-height: 27.07px;
  color: var(--primary1000);
  padding: 30px 37px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.pillarsSection {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 150px;
  row-gap: 0px;
  justify-items: center;
  z-index: 100;
}

.pillarsSectionCenter {
  margin:0 auto;
}
