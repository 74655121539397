.description {
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 10px;
    line-height: 14px;
    color: var(--primary1000);
    padding: 0 8px;
}

.imgHormoneLevelContainer{
    margin-top: 40px;
    margin-left:20px;
}



