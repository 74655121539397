.servicesDescription {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 15px;
  line-height: 18px;
  padding-left: 25px;
  margin-bottom: 15px;
}

.sectionContainer {
  list-style: none;
  display: flex;
  flex-direction: column;
}
