.title {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 13px;
  line-height: 17px;
  color: var(--primary910);
  text-align: center;
}

.value {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 12px;
  line-height: 15px;
  margin-top: 5px;
  text-align: center;
}

.heartTag {
  position: absolute;
  top: 45px;
  left: 240px;
  transform: translate(-50%, -50%);
}

.lungsTag {
  position: absolute;
  top: 45px;
  right: 195px;
  transform: translate(-50%, -50%);
}

.sleepTag {
  position: absolute;
  top: 190px;
  right: 50px;
  transform: translate(-50%, -50%);
}

.nervousSystemTag {
  width: 62px;
  position: absolute;
  bottom: 140px;
  right: 20px;
  transform: translate(-50%, -50%);
}

.cellFunctionTag {
  position: absolute;
  bottom: 5px;
  right: 140px;
  transform: translate(-50%, -50%);
}

.boneHealthTag {
  position: absolute;
  bottom: 5px;
  left: 245px;
  transform: translate(-50%, -50%);
}

.metabolismTag {
  position: absolute;
  bottom: 160px;
  left: 70px;
  transform: translate(-50%, -50%);
}

.postureTag {
  position: absolute;
  top: 195px;
  left: 90px;
  transform: translate(-50%, -50%);
}
