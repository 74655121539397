.graphContainer{
    position: relative;
    margin:30px auto;
    width: 700px;
    padding-bottom: 20px;
    /*background-color: red;*/
}

.graphImg{
    width: 700px
}

.text{
    font-family: "AktivGroteskEx_A_Md", serif;
    font-size: 10px;
    line-height: 14px;
    color: var(--primary1000);
}

.yAxis{
    font-family: "AktivGroteskEx_A_Md", serif;
    font-size: 12px;
    line-height: 14px;
    position:absolute;
    top:35px;
    left:-85px;
    transform: rotate(-90deg) translate(-50%, -50%);
}

.legend{
    width:130px;
    position:absolute;
    top:0;
    left:70px;
}

.labelBarOne{
    width:85px;
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 8px;
    text-align: center;
    position:absolute;
    top:135px;
    left:20px;
}

.labelBarTwo{
    width:85px;
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 8px;
    text-align: center;
    position:absolute;
    top:20px;
    left:220px;
}

.labelBarThree{
    width:85px;
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 8px;
    text-align: center;
    position:absolute;
    top:45px;
    left:475px;
}

.xAxisOne{
    font-family: "AktivGroteskEx_A_Rg", serif;
    position:absolute;
    bottom:43px;
    left:25px;
}

.xAxisTwo{
    font-family: "AktivGroteskEx_A_Rg", serif;
    position:absolute;
    bottom:43px;
    left:155px;
}

.xAxisThree{
    font-family: "AktivGroteskEx_A_Rg", serif;
    position:absolute;
    bottom:43px;
    left:287px;
}

.xAxisFour{
    font-family: "AktivGroteskEx_A_Rg", serif;
    position:absolute;
    bottom:43px;
    left:374px;
}

.xAxisFive{
    font-family: "AktivGroteskEx_A_Rg", serif;
    position:absolute;
    bottom:43px;
    left:490px;
}

.xAxisSix{
    font-family: "AktivGroteskEx_A_Rg", serif;
    position:absolute;
    bottom:43px;
    left:610px;
}

.xAxis{
    font-family: "AktivGroteskEx_A_Md", serif;
    width:100%;
    text-align: center;
    font-size: 10px;
    line-height: 14px;
    position:absolute;
    bottom:0;
    left:0;
}
