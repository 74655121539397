.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    height:45px;
}

.inner {
    width:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.organImage{
    height:32px
}

.organSubtitle{
    font-family: "AktivGroteskEx_A_Rg", sans-serif;
    font-size: 8px;
    line-height: 10px;
    text-align: center;

}

.organTitle{

    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 10px;
    line-height: 11px;
    margin-left: 2px;
    flex:1

}

.organDescription{
   flex:1.7;
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 11px;
    line-height: 12px;
}
