.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    position: relative;
}

.inner {
min-width: 146px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cardContainer{
    width: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap:100px
}

.cardContainerText{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconCardTitle {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    margin: 8px auto 0;
}

.cardText{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin: 0 auto;
}

.bracketContainer {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

}

.bracketInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:8px;
}

.bracketIconStyle{
    width:29px;
    height:339px
}


