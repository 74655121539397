.tabs{
    width:100%;
    margin: 14px auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.tabTitle{
    width: 350px;
    height:100%;
    padding:8px ;
    background-color:var(--primary50);
    border-radius: 8px;
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    color:var(--primary1000);
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.tabBar{
    width: 250px;
    align-self: center;

}

.tabText{
    width: 250px;
    background-color:var(--primary50);
    height:100%;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
}

.iconArrow{
  margin: 0 10px;

}
