.tab {
  width: 240px;
}
.title {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 14.3741px;
  line-height: 18px;
  width: 100%;
  background-color: var(--primary50);
  padding: 11px;
  text-align: center;
  border-radius: 7.18706px 7.18706px 1.79677px 1.79677px;
  margin-bottom: 16px;
}

.description {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 0 14px;
}
.listIcon {
  width: 18.23px;
  margin-top: 7px;
}

.listText {
  font-size: 14.3741px;
  line-height: 18px;
}

.listText {
  font-size: 14.3741px;
  line-height: 18px;
}
