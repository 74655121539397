.container {
  width: 100%;
  margin: 0 auto;
}

.tableTitles {
  padding: 17px 20px;
  display: flex;
  gap: 2px;
  align-items: center;
}

.tableTitle1 {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 14px;
  line-height: 18px;
}
.tableTitle2 {
  font-size: 14px;
  line-height: 18px;
}
