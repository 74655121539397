.sub {
  margin: 0px 10px;
  /* margin-top: 7.52px; */
  flex: 1;
}
.inner {
  display: flex;
  gap: 8px;
  align-items: center;
}

.title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 13.3458px;
  line-height: 26px;
  margin-bottom: 0;
}

.subText {
  font-family: "AktivGroteskEx_A_Rg";
  font-size: 9.35194px;
  line-height: 15px;
  margin-bottom: 5px;
}

.icon {
  width: 9px;
}

.icon img {
  width: 100%;
}
