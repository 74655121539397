.chartTitle {
    width:312px;
    border-bottom: 1px solid var(--primary400);
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    top: -55px;
    left: 70px;
}

.title {
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
}

.value {
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
}

.cardioTag {
    align-items: center;
    gap: 3px;
    position: absolute;
    top: 0;
    left: 223px;
    transform: translate(-50%, -50%);
}

.resistanceTag {
    align-items: center;
    gap: 3px;
    position: absolute;
    top: 310px;
    right: -40px;
    transform: translate(-50%, -50%);
}

.intervalTag {
    align-items: center;
    gap: 3px;
    position: absolute;
    top: 310px;
    left: 62px;
    transform: translate(-50%, -50%);
}

