.imgContainer{
    width:300px;
    margin: 0 auto 25px;
    overflow: hidden;
    padding-top: 37.75px;
}

.imgItem{
    width:100%;

}
