
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.label {
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    color: var(--primary1000);
    font-size: 13px;
    line-height: 14px;
    margin-bottom: 8px;
}

.inner {
    width: 250px;
    height: 31px;
    background-color: var(--primary50);
    border-radius: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bar {
    width: 230px;
    height: 15px;
    border-radius: 25px;

}

.fill {
    background-color: var(--primary800);
    width: 100%;
    height: 100%;
    border-radius: 25.8012px;

}

.excellent {
    background-color: var(--secondary12);
}

.good {
    background-color: var(--secondary07);
}

.neutral {
    background-color: var(--secondary20);
}

.limitations {
    background-color: var(--secondary50);
}

.severeLimitation {
    background-color: var(--secondary40);
}

