.navItem {
  padding: 4px;
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 12.9436px;
  line-height: 23px;
  text-align: center;

  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-right: 2px solid var(--primary50);
}

.navItemExcellent {
  padding: 4px;
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 12.9436px;
  line-height: 23px;
  text-align: center;

  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.core {
  border: none;
  background-color: var(--primary50);
  border-radius: 20px;
}

.value {
  width: 100%;
}
.category {
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.excellent {
  background-color: var(--secondary12);
}
.good {
  background-color: var(--secondary07);
}

.neutral {
  background-color: var(--secondary20);
}

.limitations {
  background-color: var(--secondary50);
}
.severeLimitation {
  background-color: var(--secondary40);
}
