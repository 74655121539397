.pointsAge {
  width: 494px;
  height: 247px;

  position: absolute;
  top: 5px;
  z-index: 0;
}

.pointAge0 {
  width: 55px;
  position: absolute;
  top: 220px;
  left: -24px;
}

.pointAge10 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 175px;
  left: 2px;
  transform: rotate(18deg);
}

.pointAge20 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 110px;
  left: 35px;
  transform: rotate(35deg);
}

.pointAge30 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 58px;
  left: 86px;
  transform: rotate(53deg);
}

.pointAge40 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 26px;
  left: 152px;
  transform: rotate(71deg);
}

.pointAge50 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 13px;
  left: 224px;
  transform: rotate(90deg);
}

.pointAge60 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 25px;
  right: 173px;
  transform: rotate(-71deg);
}

.pointAge70 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 57px;
  right: 107px;
  transform: rotate(-54deg);
}

.pointAge80 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 109px;
  right: 55px;
  transform: rotate(-35deg);
}

.pointAge90 {
  width: 25px;
  height: 2px;
  background-color: var(--secondary22);
  position: absolute;
  top: 174px;
  right: 22px;
  transform: rotate(-18deg);
}
.pointAge100 {
  width: 55px;
  position: absolute;
  top: 220px;
  right: -3px;
}
