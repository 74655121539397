.backgroundImg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    /*background-color: #0da488;*/
    /*opacity: 0.5;*/
}

.pillarsSectionTop {
    /*padding-top: 8px;*/
    position:absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);

}

.pillarsSectionBottom {
    display: flex;
    gap: 160px;
    justify-content: space-between;
    align-items: flex-start;
    position:absolute;
    bottom:20px;
    left:50%;
    transform: translateX(-50%);
}

.circle {
    width: 550px;
    height: 550px;
    border-radius: 50%;
    border: 1px solid var(--primary50);
    opacity:0.4;
    margin: 0px auto;
    position:absolute;
    top:220px;
    left:52%;
    transform: translateX(-50%);

}
