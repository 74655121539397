.title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 17.7695px;
  line-height: 23px;
  text-align: center;
}

.value {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 15.231px;
  line-height: 19px;
  margin-top: 10px;
  text-align: center;
}

.cardioTag {
  position: absolute;
  top: -20px;
  left: 350px;
  transform: translate(-50%, -50%);
}

.resistanceTag {
  position: absolute;
  top: 405px;
  right: -50px;
  transform: translate(-50%, -50%);
}

.intervalTag {
  position: absolute;
  top: 405px;
  left: 130px;
  transform: translate(-50%, -50%);
}
