.section {
  position: relative;
}

.subtitle {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 14px;
  line-height: 17px;
  margin-left: 41px;
  margin-top: 11px;
}

.biaxial {
  font-size: 8.5258px;
  line-height: 17px;
  margin-left: 60px;
}

.legend {
  margin-left: 70px;
}

.legend2 {
  display: flex;
  gap: 15px;
  align-items: center;
}
