.imgTherapyContainer{
    padding: 30px 16px 0;
    width:100%;
    height:260px;
    /*margin-top:-10px*/
    position:relative;
}

.titleContainer{
    width:55%;
    position: absolute;
    top:35%;
    left:30px;
}

.title {

    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 25px;
    line-height: 27.87px;
    color: var(--primary1000);
    padding: 30px 7px;
}

.imgTherapy {
    border-radius: 10px;
    width:100%;
    height:210px;
    object-fit: cover;
    overflow: hidden;
}

.subTitleContainer{
    display: flex;
    margin: 16px auto 24px;
    justify-content: space-between;
    width:96%
}

.subTitle1{
    background-color:var(--primary50) ;
    width:350px;
    height:45px;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    color:var(--primary1000);
    font-size: 18px;
    line-height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subTitle2{
    background-color:var(--primary50) ;
    width:250px;
    height:45px;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    color:var(--primary1000);
    font-size: 18px;
    line-height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section{
    width:96%;
    margin: 0 auto 30px;
    border-top: 0.4px solid var(--primary1000);
    position: relative;
    padding-top: 8px;
}

.sectionTitle{
    width:32%;
    background-color: var(--primary100);
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    text-align: center;
    color:var(--primary1000);
    font-size: 19px;
    line-height: 24px;
    position: absolute;
    top:0;
    left:50%;
    transform: translate(-50%, -50%);
}


