.xAxisLabel {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 8.59744px;
  line-height: 12px;
  position: absolute;
  bottom: 0px;
  left: 30%;
  transform: translateX(-50%);
}

.yAxisLabelLeft {
  display: inline-block;
  width: 100px;
  font-size: 8.59744px;
  line-height: 12px;
  position: absolute;
  top: 48%;
  left: -15px;
  transform: translateY(-50%) rotate(-90deg);
}

.legend1 {
  text-align: center;
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
}
.legend2 {
  text-align: center;
}

.yAxisLabelRight {
  display: inline-block;
  width: 100px;
  font-size: 8.59744px;
  line-height: 12px;
  position: absolute;
  top: 45%;
  right: 0px;
  transform: translateY(-50%) rotate(90deg);
}
