.section1 {
  display: flex;
  gap: 10px;
  margin-top: 50px;
}

.chartContainer {
  position: relative;
  margin: 0 auto;
  padding-top: 10px;
  height: 700px;
}

.imgRadarChart {
  margin-left: -10px;
}

.section2 {
  margin-top: 15px;
}

.footer {
  margin-top: 60px;
}
