body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary50: #ffffff;
  --primary100: #f3f5f8;
  --primary100-opacity1: #f3f5f8c2;
  --primary200: #e7e7e7;
  --primary300: #eff1f2;
  --primary400: #bbbbbb;
  --primary600: #939393;
  --primary800: #444444;
  --primary850: #6c6b6b;
  --primary900: #666666;
  --primary910: #777777;
  --primary950: #999999;
  --primary1000: #000000;

  --secondary06: #00CAB3;
  --secondary07: #27f3ce;
  --secondary08: #65e0d1;
  --secondary09: #01bca7;
  --secondary10: #3ecba1;
  --secondary11: #08a764;
  --secondary12: #10db86;
  --secondary13: #42d0b8;
  --secondary14: #0da488;
  --secondary15: #0e836e;
  --secondary19: #019cef;
  --secondary20: #2c7cfe;
  --secondary21: #2479f0;
  --secondary22: #2a53e2;
  --secondary23: #778bd2;
  --secondary24: #506bca;
  --secondary25: #183fc8;
  --secondary26: #0f44ff;
  --secondary30: #9144fc;
  --secondary31: #690996;
  --secondary32: #5b0e80;
  --secondary33: #5b0e80;
  --secondary40: #ec486e;
  --secondary50: #f08624;

  --NEUTRAL_BG_MAIN: #121417;
  --NEUTRAL_TEXT_STRONG: #E6F1FF;
  --NEUTRAL_BORDER_MAIN: #BBC2C9;
}
