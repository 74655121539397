* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  position: relative;
}

@font-face {
  font-family: "AktivGroteskEx_A_Bd";
  src: local("AktivGroteskEx_A_Bd"), url("./fonts/AktivGroteskEx_A_Bd.ttf");
}
@font-face {
  font-family: "AktivGroteskEx_A_Lt";
  src: local("AktivGroteskEx_A_Lt"), url("./fonts/AktivGroteskEx_A_Lt.ttf");
}
@font-face {
  font-family: "AktivGroteskEx_A_Md";
  src: local("AktivGroteskEx_A_Md"), url("./fonts/AktivGroteskEx_A_Md.ttf");
}
@font-face {
  font-family: "AktivGroteskEx_A_Rg";
  src: local("AktivGroteskEx_A_Rg"), url("./fonts/AktivGroteskEx_A_Rg.ttf");
}
@font-face {
  font-family: "AktivGroteskEx_A_XBd";
  src: local("AktivGroteskEx_A_XBd"), url("./fonts/AktivGroteskEx_A_XBd.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"), url("./fonts/Poppins-Light.ttf");
}
