.inner {
  width: 153px;
  height: 118px;
  padding: 5px;
  background-color: var(--primary300);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd";
  text-align: center;
}

.subTitle {
  font-size: 10.51px;
  line-height: 13.62px;
  text-align: center;
}

.subTitleBigFonts{
  font-size: 14.2px;
}

.highlighted {
  width: 135px;
  height: 41px;
  font-family: "AktivGroteskEx_A_Bd";
  padding-top: 12px;
  font-size: 14.2024px;
  line-height: 17px;
  background: var(--secondary13);
  /*box-shadow: 0px 0px 12px #FFFFFF;*/
  border-radius: 7px;
}