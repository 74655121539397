.container{
    width:744px;
    height:431px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.imgBreathScoreContainer{
    margin-top: 80px;
}

.innerTop{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
    position:relative;
}

.innerBottom{
    display: flex;
    justify-content: space-between;
}

.centered{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.boxes{
    display: flex;
    flex-direction: row;
    gap:100px;
    margin-top:8px;
}

.boxesCenter{
    /*position:absolute;*/
    /*top:50%;*/
    /*left:50%;*/
    /*transform: translateX(-50%);*/
}

.boxesLeftRight{
    display: flex;
    align-items: center;
    gap:32px;
    position:relative;
}

.box03Container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:20px;
}

.box01{
    width:128px;
    height:128px;
    border-radius: 50%;
    background-color: var(--primary50);
    text-align: center;
    padding: 0 8px;
    color:var( --secondary22);
    font-family: "AktivGroteskEx_A_XBd", serif;
    font-size: 16px;
    margin:0 auto 16px;
    position: relative;
}

.box02{
    width:88px;
    height:88px;
    border-radius: 50%;
    background-color: var(--primary50);
    text-align: center;
    color:var( --secondary22);
    font-family: "AktivGroteskEx_A_XBd", serif;
    font-size: 13px;
    line-height: 14px;
    margin:0 auto;
    position: relative;
}

.box03{
    width:141px;
    height:40px;
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    background-color: var(--primary50);
    border-radius: 20px;
    position: relative;
    border:2px solid var(--primary50)
}

.line01{
    width:70px;
    height:3px;
    background-color: var(--primary1000);
    position:absolute;
    top:290px;
    left:425px;
    transform: rotate(15deg);
}

.line02{
    width:70px;
    height:3px;
    background-color: var(--primary1000);
    position:absolute;
    top:290px;
    left:250px;
    transform: rotate(-15deg);
}

.line03{
    width:70px;
    height:3px;
    background-color: var(--primary1000);
    position:absolute;
    top:190px;
    left:335px;
    transform: rotate(90deg);
}

.line04{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:60px;
    left:335px;
    transform: rotate(90deg);
}

.line05{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:85px;
    left:385px;
    transform: rotate(135deg);
}

.line06{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:85px;
    left:285px;
    transform: rotate(45deg);
}

.line07{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:50px;
    left:120px;
    transform: rotate(30deg);
}

.line08{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:130px;
    left:120px;
    transform: rotate(-30deg);
}
.line09{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:90px;
    left:120px;
    /*transform: rotate(-30deg);*/
}

.line10{
    width:100px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:140px;
    left:240px;
    transform: rotate(30deg);
}

.line11{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:135px;
    left:65px;
    transform: rotate(30deg);
}
.line12{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:45px;
    left:65px;
    transform: rotate(-30deg);
}
.line13{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:90px;
    left:65px;
    /*transform: rotate(-30deg);*/
}
.line14{
    width:70px;
    height:0.5px;
    background-color: var(--primary950);
    position:absolute;
    top:130px;
    left:-50px;
    transform: rotate(-30deg);

}







