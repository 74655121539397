.inner {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 5px;
}

.label {
  font-family: "AktivGroteskEx_A_Lt", serif;
  font-size: 8.65798px;
  line-height: 13px;
}

.arrow {
  height: 14.45px;
}

.dot {
  width: 10.82px;
  height: 10.82px;
  border-radius: 50%;
  background-color: var(--primary950);
}
