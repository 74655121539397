.container{
 width: 794px;
    margin-left: -20px;
    padding-left: 20px;
}

.img{
    margin-top:-10px
}

.sectionTitle{
    width:312px;
    border-bottom: 1px solid var(--primary400);
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    margin:0 auto 32px;
}

.section2Header{
    display: flex;
    justify-content: space-between;
    width:580px;
    margin:20px auto 0;
}

.header{
    width:190px;
    background-color: var(--primary50);
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 14px;
    padding:4px;
    text-align: center;
    border-radius: 16px;
}

.section2{
    width: 794px;
    display: flex;
    gap:90px;
    padding-left: 30px;
}

.rowItems{
    font-size: 10px;
    padding: 2px;
    line-height: 35px;
    flex-direction: column;
    flex: 1;
}

.rowItem {
    display: flex;
    align-items: center;
    border-bottom: 3px solid var(--primary100);
    list-style: none;
    padding: 0 12px;
}

.rowItem:first-child,.cell:first-child {
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 14px;
}

.rowItem:last-child {
    border-bottom: none;
}

.cellItem {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 20px;
    list-style: none;
}

.cell {
    flex: 1;
    text-align: center;
}




