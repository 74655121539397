.section3 {
  width: 97%;
  margin: 23px auto;
  display: flex;
  justify-content: space-between;
}

.chart {
  width: 515px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 13.3784px;
  line-height: 14px;
}

.description {
  font-size: 10px;
  line-height: 12px;
  margin-top: 13px;
}

.inner {
  position: relative;
  width: 460px;
  height: 30.58px;
  margin-top: 30px;
  background-color: var(--primary100);
  border-radius: 58.2916px;
}

.container {
  width: 440px;
  height: 15.29px;
  border-radius: 25.8012px;
  background-color: var(--secondary12);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: 0 10px;
}

.fill {
  background-color: var(--secondary22);
  width: 100%;
  height: 100%;
  border-radius: 25.8012px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.labels {
  width: 100%;
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 13.3784px;
  line-height: 14px;
  margin-top: 11px;
  margin-bottom: 40px;
  position: relative;
}

.point0 {
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateX(-50%);
}

.point25 {
  position: absolute;
  top: 0;
  left: 120px;
  transform: translateX(-50%);
}
.point50 {
  position: absolute;
  top: 0;
  left: 230px;
  transform: translateX(-50%);
}
.point75 {
  position: absolute;
  top: 0;
  left: 340px;
  transform: translateX(-50%);
}
.point100 {
  position: absolute;
  top: 0;
  left: 450px;
  transform: translateX(-50%);
}

.legend {
  width: 216px;
}
