.section1 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  position:absolute;
  bottom:14px;
  right:14px;
}

.section2 {
  margin-top: 25px;
}

.imgBiologicalAge {
  /*margin-left: 15px;*/
  flex: 0 0 754px;
}

.description {
  width: 656px;
  font-size: 12px;
  line-height: 17px;
  margin: 0 auto;
  margin-top: 15px;
}
.landscapeLayout{
  transform: rotate(-90deg);
  width:1123px;
  height:794px;
  position:absolute;
  top:164px;
  left:-164px;
  /*position:relative;*/


}

.title{
  position: absolute;
  top: -10px;
  left:40px;
}

