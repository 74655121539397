.date {
  font-family: "AktivGroteskEx_A_Lt";
  font-size: 11.8116px;
  line-height: 19px;
  color: var(--primary950);
}

.inner {
  height: 30px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  width: 17px;
  height: 17px;
  background: var(--primary50);
  border: 1.18116px solid var(--secondary26);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.highlightedIcon {
  width: 34px;
  height: 34px;

  position: absolute;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
}

.line {
  flex: 1;
  height: 2px;
  margin-left: 9px;
  background-color: var(--secondary26);
}

.period {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 10px;
  line-height: 19px;
  color: var(--secondary26);
  margin: 0 5px;
}

.type {
  width: 100%;
  margin-top: 9.45px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.typeLabel {
  font-size: 11.8116px;
  line-height: 19px;
  padding: 7px 18.87px;
  margin-left: 8px;
  margin-bottom: 1px;
  border-left: 0.322166px dashed var(--secondary26);
  position: relative;
}

.typeIcon {
  width: 9.45px;
  height: 9.45px;
  background-color: var(--primary900);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.typeIcon2 {
  width: 9.45px;
  height: 9.45px;
  background-color: var(--secondary21);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}
