.container {
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  width: 794px;
  height: 1123px;
  background-color: var(--primary100);
  padding: 0 20px;
  position: relative;
  border-bottom: 1px solid var(--primary50);

  z-index: 0;
}
