.container {
  width: 92%;
  margin: 0 auto;
  display: flex;
  gap: 50px;
  align-items: flex-start;
  margin-bottom: 50px;
}

.imgTherapy {
  width: 282.66px;
  height: 240.56px;
  border-radius: 10px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 18.042px;
  line-height: 23px;
  margin-bottom: 8px;
}

.description {
  font-family: "AktivGroteskEx_A_Md";
  font-size: 10px;
  line-height: 15px;
}
.tabs {
  width: 95%;
  margin: 0 auto;
  display: flex;
  gap: 11px;
}

.section {
  height: 490px;
  padding-top: 20px;
  border-bottom: 1px solid var(--primary200);
}

.section:last-child {
  border-bottom: none;
}
