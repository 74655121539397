.container {
  width: 330px;
}

.description {
  font-size: 12px;
  line-height: 17px;
}

.tableHeader {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 15px;
  line-height: 19px;
  margin-top: 32px;
  margin-bottom: 18px;
}

.tableFooter {
  width: 95%;
  font-size: 14px;
  line-height: 18px;
  margin-top: 25px;
}
