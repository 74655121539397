.title {
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.icon {
    width: 11px;
    height: 20px;
    background-color: var(--primary950);
}

.description {
    margin-top: 0px;
}

.columns {
    list-style: none;
    display: flex;

    gap: 10px;
}

.sub {
    width: 221px;
}
