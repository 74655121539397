.section2 {
  width: 95%;
  margin: 23px auto;
  border-left: 5px solid var(--secondary09);
  border-right: 5px solid var(--secondary19);
  display: flex;
  justify-content: space-between;
}

.innerLeft {
  padding: 0 10px;
}
.innerRight {
  text-align: right;
  padding: 0 10px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 14.6659px;
  line-height: 33px;
}

.subTitle {
  font-size: 13.2216px;
  line-height: 30px;
}

.value {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 13.2216px;
  line-height: 30px;
}
