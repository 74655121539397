.navBar {
  display: flex;
  justify-content: space-between;
  gap: 1px;
  list-style: none;
  padding-left: -15px;
  margin-top: -25px;
}

.navItem {
  flex: 1;
  margin: 0 4px;
  padding: 5px 0px;
}
