.axis {
  position: relative;
  position: absolute;
  top: 44px;
  left: 147px;

  width: 100%;
  height: 100%;
  z-index: 0;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--primary1000);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}
.line1 {
  position: relative;
  width: 230px;
  height: 3px;
  background-color: var(--primary1000);
  position: absolute;
  top: 262px;
  left: 188px;
  transform: rotate(30deg);
}

.line2 {
  width: 230px;
  height: 3px;
  background-color: var(--primary1000);
  position: relative;
  transform: rotate(270deg);
  position: absolute;
  top: 89px;
  left: 88px;
}

.line3 {
  width: 230px;
  height: 3px;
  background-color: var(--primary1000);
  position: relative;
  transform: rotate(150deg);
  position: absolute;
  top: 262px;
  left: -12px;
}
