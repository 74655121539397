.card {
    width: 135px;
    height: 107px;
    background-color: var(--primary100);
    border-radius: 12px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
