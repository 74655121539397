.subTitle {
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  font-size: 20px;
  line-height: 27.87px;
  color: var(--primary1000);
  padding: 0 17px;
  margin-top: 10px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  font-size: 13.3784px;
  line-height: 14px;
}

.imgRiskBar {
  margin-top: 100px;
}

.titleStress{
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  font-size: 13.3784px;
  line-height: 14px;
  margin-left:10px;
}

.imgStressBar{
  margin-top:24px;
  margin-left:16px
}
