.chart {
  padding-top: 18px;
  padding-left: 7px;
}

.inner {
  width: 95%;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

.sub {
  flex: 1;
}

.card_container {
  margin-top: 0px;
}
.suggestion_tables_title {
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 18.6013px;
  line-height: 34px;
  width: 93%;
  margin: 0 auto;
}
.suggestion_tables {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.bottom {
  list-style: none;
  width: 95%;
  margin: 0 auto;
  margin-top: 7px;
  margin-bottom: 0px;
}
