.xAxisLabel {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-family: "AktivGroteskEx_A_Bd";
  font-size: 8.59744px;
  line-height: 12px;
  position: absolute;
  bottom: 40px;
  left: 53%;
  transform: translateX(-50%);
}

.yAxisLabelLeft {
  display: inline-block;
  width: 100px;
  font-size: 8.59744px;
  line-height: 12px;
  position: absolute;
  top: 45%;
  left: 62px;
  transform: translateY(-50%) rotate(-90deg);
}

.legend1 {
  text-align: center;
  font-family: "AktivGroteskEx_A_Bd";
}
.legend2 {
  text-align: center;
}

.yAxisLabelRight {
  display: inline-block;
  width: 100px;
  font-size: 8.59744px;
  line-height: 12px;
  position: absolute;
  top: 45%;
  right: 0px;
  transform: translateY(-50%) rotate(90deg);
}
