
.pillar {
    width: 270px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    width: 201px;
    /*height: 103px;*/
    margin-bottom: 8px;
}

.icon3 {
    width: 310px;

    margin-bottom: 8px;
}


.container {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px;
    position: relative;
    background-color: #ddd;
}

.imgBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.inner {
    width: 100%;
    position: absolute;
}

.pillarTitle {
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    z-index: 100;
    margin-top: 4px;
}

.pillarTitleHide {
    margin-top: 4px;
    color: transparent;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}

.description {
    font-size: 9px;
    line-height: 12px;
    margin-bottom: 4px;
    padding: 0 8px;
    text-align: center;
}

.descriptionHide {
    color: transparent;
    margin-bottom: 4px;
    font-size: 9px;
    line-height: 12px;
    padding: 0 8px;
    text-align: center;
}

.iconText {
    display: flex;
    gap: 11px;
    width: 310px;
    margin-left: 6px;
    margin-top: -5px;

}

.textContainer {
    width: 68px;
    border-radius: 10px;
    background-color: #ddd;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
}
