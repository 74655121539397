.axis {
  position: relative;
  position: absolute;
  top: -6px;
  left: 17px;

  width: 100%;
  height: 100%;
  z-index: 0;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--primary1000);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}
.line1 {
  position: relative;
  width: 180px;
  height: 2px;
  background-color: var(--primary1000);
  position: absolute;
  top: 250px;
  left: 191px;
  transform: rotate(30deg);
}

.line2 {
  width: 180px;
  height: 2px;
  background-color: var(--primary1000);
  position: relative;
  transform: rotate(270deg);
  position: absolute;
  top: 115px;
  left: 113px;
}

.line3 {
  width: 180px;
  height: 2px;
  background-color: var(--primary1000);
  position: relative;
  transform: rotate(150deg);
  position: absolute;
  top: 250px;
  left: 35px;
}
