.container {
  width: 650px;
  padding: 20px 40px;
  border-radius: 20px;
  box-shadow: 1px 3px 5px var(--primary400);
  background-color: var(--primary100);
  margin: 0 auto;
  margin-top: 200px;
}

.logoContainer {
  width: 90%;
  margin: 0 auto;
}

.logo {
  width: 100%;
  text-align: center;
}

.text1 {
  font-family: "AktivGroteskEx_A_Bd";
  color: var(--secondary22);
  text-align: center;
  font-size: 24px;
}

.text2 {
  font-family: "AktivGroteskEx_A_Rg";
  text-align: center;
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.link {
  font-family: "AktivGroteskEx_A_Bd";
  color: var(--secondary22);
}

.link:hover {
  text-decoration: underline;
  color: var(--secondary23);
}
