.logoContainer {
  width: 180px;
  margin: 0 auto;
  align-items: center;
  padding-top: 20px;
  position: relative;
  overflow: hidden;

}

.logo {
  width: 100%;
}

.header {
  width: 80%;
  margin: -10px auto 0;
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 26px;
  line-height: 46px;
  text-align: center;
  padding-bottom: 9px;
  border-bottom: 2px solid #FFFFFF;
}

.subTitle {
  width: 100%;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  margin-top:9px;
  margin-bottom: 30px;
}

.subjectName{
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 26px;
  line-height: 28px;
  text-align: center;
}

.imgContainer{
  width:684px;
  height:492px;
  margin: 30px auto 27px;
  border-radius: 11px;

  overflow: hidden;
}

.imgItem{
  width:100%
}

.bottom{
  width:86%;
  margin:0 auto;
  display: flex;
  font-size: 17px;
  line-height: 21px;
}

.bottomLeft{
  flex:2;
}
.bottomRight{

  flex:1;
}

.testTypeLabel{
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  margin-top: 70px;
}

.testData{
  margin-top: 23px;
  font-size: 15.37px;
}

.testDateValue{
  font-family: "AktivGroteskEx_A_Lt", sans-serif;
}

.logo2Container{

  align-items: center;
  justify-content: right;
  height: 130px;

  position: relative;
  overflow: hidden;
margin-bottom: 10px;
}

.logo2{
  width: 70%;
  position: absolute;
  top: 50%;
  right:0;
  transform: translateY(-50%);
}

.affiliateName {

  width:380px;
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 15.37px;
  line-height: 23.43px;
  text-align: right;
}

.footer {
  width:86%;
  font-family: "AktivGroteskEx_A_XBd", sans-serif;
  display: flex;
  justify-content: space-between;
  font-size: 15px;

  position: absolute;
  bottom: 24px;
  left:55px

}

.instagram{
  display: flex;
  align-items: center;
  gap:8px;
}



/*SECOND PAGE*/
.backgroundImg {
  width: 794px;
  height: 1123px;
  position: absolute;
  z-index: -3;
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 380px;
  z-index: 0;
  /*background: linear-gradient(111.59deg, rgba(255, 255, 255, 0.49) -2.03%, rgba(255, 255, 255, 0.04) 105.71%);*/
  /*box-shadow: 9px 9px 10px rgba(255, 255, 255, 0.04);*/
  /*backdrop-filter: blur(20px);*/
}

.disclaimer {
  font-size: 14px;
  line-height: 27px;
  padding:42px;

}

.disclaimer span {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
}

.rectangle200 {
  width: 100%;


  position: absolute;
  z-index: 0;
  top: 380px;
}
