.header{
    display: flex;
    align-items: center;
    gap: 8px
}

.sectionTitle{
    font-family: "AktivGroteskEx_A_Md", serif;
    font-size: 16px;

}

.list{
    margin-top:10px;
    padding-left: 16px;
}

.listItem{
    font-family: "AktivGroteskEx_A_Rg", serif;
    font-size: 10.5px;
    line-height: 16px;
    margin:8px 0;
    margin-left: 36px;

}

.rowItems{
    font-size: 10px;
    padding: 2px;
    line-height: 35px;
    flex-direction: column;
    flex: 1;
}

.rowItem {
    display: flex;
    align-items: center;
    border-bottom: 3px solid var(--primary100);
    list-style: none;
    padding: 0 12px;
}

.rowItem:first-child,.cell:first-child {
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 14px;
}

.rowItem:last-child {
    border-bottom: none;
}

.cellItem {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 20px;
    list-style: none;
}

.cell {
    flex: 1;
    text-align: center;
}

