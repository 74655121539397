.qrcode {

  /*sourcesStyle: string | undefined;*/
  display: flex;

}

.qrcodeInner2 {
  margin-top: -4px;
  display: flex;
  flex-direction: column;
}

.qrcodeImg {
  width: 100px;
  height: 100px;
}

.header2 {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 7px;
  line-height: 8px;
  margin: -2px 10px 4px;


}



.qrcodeInner {
flex:1
}

.footer1 {
  font-family: "AktivGroteskEx_A_Md", sans-serif;
  font-size: 11px;
  line-height: 23px;
  text-align: left;
  margin-top:4px
}

.sourcesStyle{
  display: flex;

  list-style: none;
}

.column{
  flex:1;
}

.listItem{
  font-family: "AktivGroteskEx_A_Lt", sans-serif;
  font-size: 8.5px;
  line-height: 11px;
  display: flex;
  flex-direction: row;
  gap:8px;
  align-items: flex-start;
}

.point{
  width:3px;
  height:3px;
  border-radius: 50%;
  background-color: var(--primary1000);
  margin-top:4px;

}





