.section1 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 25px;
}

.section2 {
  margin-top: 25px;
}

.imgBiologicalAge {
  margin-left: 15px;
  flex: 0 0 754px;
}

.description {
  width: 656px;
  font-size: 12px;
  line-height: 17px;
  margin: 0 auto;
  margin-top: 15px;
}
