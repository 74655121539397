.subTitle {
  font-size: 10px;
  line-height: 14px;
  margin-top: 17px;
}

.container {
  position: relative;
  width: 670px;
  height: 30.58px;
  background-color: var(--primary100);
  border-radius: 58.2916px;
  /* margin: 0 auto; */
  margin-top: 10px;
}

.inner {
  width: 650px;
  height: 15.29px;
  border-radius: 25.8012px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin: 0 10px;
}

.fillNoRisk {
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgb(18, 63, 227) 0%,
    rgba(115, 76, 188, 0.6) 77.06%,
    rgba(207, 86, 152, 0) 100%
  );
  border-radius: 25.8012px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.fillRisk {
  background: linear-gradient(
    270deg,
    rgb(240, 36, 104) 6.13%,
    rgba(225, 143, 20, 0.5) 93%,
    rgba(207, 86, 152, 0) 100%
  );
  width: 50%;
  height: 100%;
  border-radius: 25.8012px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.noActive {
  opacity: 0.2;
}

.balancePoint {
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.labels {
  width: 670px;
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 13px;
  line-height: 23px;
  display: flex;
  margin-top: 6px;
}

.riskLabel {
  flex: 1;
  text-align: center;
}
