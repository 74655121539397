.inner {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  /* overflow: hidden; */
}

.chartContainer {
  position: relative;
  margin: 0 auto;
  padding-top: 50px;
  height: 700px;
}

.imgRadarChart {
  margin-left: 25px;
}

.description {
  width: 95%;

  min-height: 203px;

  margin: 0 auto;
  margin-top: 100px;
  background-color: var(--primary50);
  border-radius: 20px;
  font-size: 12px;
  line-height: 17px;
  padding: 24px 12px;
}
